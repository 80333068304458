$(window).on("load", function() {
    // extension method:
    $.fn.classChange = function(cb) {
        return $(this).each((_, el) => {
            new MutationObserver(mutations => {
                mutations.forEach(mutation => cb && cb(mutation.target, $(mutation.target).prop(mutation.attributeName)));
            }).observe(el, {
                attributes: true,
                attributeFilter: ['style'] // only listen for class attribute changes 
            });
        });
    }
  
    // usage:
    const $foo = $(".sivtc-second-step-vehicle-map-container").classChange((el, newStyle) => {
        if(newStyle.display == 'none') {
            $('.slick-form-reservation').show();
        } else {
            $('.slick-form-reservation').hide();
        }
    });
});